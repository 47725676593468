import { Amplify } from "aws-amplify"
import { AuthModeStrategyType } from "aws-amplify/datastore"
import awsExports from "./src/aws-exports"

import "@aws-amplify/ui-react/styles.css"
import "./src/styles/global.css"
import "./src/styles/module.layout.css"
import "aos/dist/aos.css"
import "react-datepicker/dist/react-datepicker.css"

Amplify.configure({
  ...awsExports,
  API: {
    GraphQL: {
      endpoint:
        "https://abnqswqnvzcnlkorws5xbtifhi.appsync-api.us-east-2.amazonaws.com/graphql",
      region: "us-east-2",
      defaultAuthMode: "apiKey",
    },
  },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    errorHandler: error => {
      console.error("Unrecoverable error: ", { error })
    },
  },
})
